<template>
    <div class="flex flex-col min-h-screen" :class="{ 'overflow-hidden h-screen': data.open || data.login }">
        <FrontpageHeader :account="account" :data="data" @show-nav="toggleNavigation" @show-login="toggleLogin" />
        <slot />

        <FrontpageFooter :account :data="data" @show-login="toggleLogin" />

        <Modal v-if="!account" v-model="data.login">
            <FrontpageLogin />
        </Modal>
    </div>
</template>

<script setup>
useHead({
    htmlAttrs: {
        lang: 'de',
    },
    bodyAttrs: {
        class: 'bg-white text-gray-800 antialiased',
    },
});

const { account } = useStore();

const data = reactive({
    open: false,
    login: false,
});

function toggleNavigation(value) {
    data.open = value;
}

function toggleLogin(value) {
    data.login = value;
}
</script>
