<template>
    <header class="text-sm">
        <!-- upper -->
        <div class="container flex items-center h-11 gap-4">
            <div>
                <span class="hidden">Tim - Ihr persönlicher</span> Service-Scout:
                <a href="+4915733191255" class="text-primary font-medium hover:underline">+49 (0) 157 33 19 12 55</a>
            </div>

            <template v-if="account">
                <Menu
                    v-if="menuElement"
                    v-model="menuListener"
                    :reference="menuElement"
                    direction="bottom-right"
                    class="mt-4"
                >
                    <div class="flex gap-4 px-4 py-3 items-center">
                        <div class="rounded-full overflow-hidden relative">
                            <button
                                type="button"
                                class="absolute inset-0 bg-black/50 flex opacity-0 hover:opacity-100 duration-300"
                                @click="noFunction"
                            >
                                <icon class="m-auto text-white" name="photo_camera" :size="28" />
                            </button>
                            <Avatar :firstname="account.firstname" :lastname="account.lastname" />
                        </div>
                        <div>
                            <div class="font-medium text-lg grid">
                                <span class="truncate">
                                    {{ account.firstname }}
                                    {{ account.lastname }}
                                </span>
                            </div>
                            <div class="truncate text-xs text-gray-500">Kd.Nr.: {{ account.customer }}</div>
                        </div>
                    </div>
                    <hr />
                    <NuxtLink to="/account">
                        <MenuItem icon="badge" @click="menuListener = false">Mein Account</MenuItem>
                    </NuxtLink>
                    <NuxtLink to="/account/einstellungen">
                        <MenuItem icon="instant_mix" @click="menuListener = false">Einstellungen</MenuItem>
                    </NuxtLink>
                    <!-- <MenuItem unavailable icon="help" @click="useNotification().noFunction()">Hilfe</MenuItem> -->
                    <!-- <MenuItem unavailable icon="maps_ugc" @click="useNotification().noFunction()">Verbesserung vorschlagen</MenuItem> -->
                    <!-- <MenuItem unavailable icon="outgoing_mail" @click="useNotification().noFunction()">Freunde einladen</MenuItem> -->
                    <hr />
                    <NuxtLink to="/logout">
                        <MenuItem icon="logout" @click="menuListener = false">Ausloggen</MenuItem>
                    </NuxtLink>
                </Menu>

                <button
                    ref="menu-button"
                    class="sm:flex gap-0.5 p-2 items-center ml-auto hidden"
                    @click="menuListener = true"
                >
                    <Avatar
                        :firstname="account.firstname"
                        :lastname="account.lastname"
                        class="text-[9px] size-5"
                        size="custom"
                    />
                    <span class="ml-2 text-primary font-medium hover:underline">
                        {{ account.firstname }} {{ account.lastname }}
                    </span>
                </button>
                <template v-if="account.admin">
                    <div class="h-4 border-r -ml-px border-gray-300 hidden sm:block"></div>
                    <NuxtLink class="text-primary font-medium hover:underline" to="/backoffice/dashboard">
                        Backoffice
                    </NuxtLink>
                </template>
            </template>
            <template v-else>
                <button class="sm:flex gap-0.5 p-2 items-center ml-auto hidden" @click="$emit('showLogin', true)">
                    <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                    <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                    <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                    <span class="ml-2 text-primary font-medium hover:underline">Login</span>
                </button>
                <div class="h-4 border-r -ml-px border-gray-300 hidden sm:block"></div>
                <button class="text-primary font-medium hover:underline hidden sm:block">Mitglied werden</button>
            </template>

            <div class="h-4 border-r border-gray-300 hidden md:block"></div>

            <button class="hover:text-primary duration-100 p-2 -mx-2 hidden md:block" @click="navigateTo('/')">
                <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                    <path
                        d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                    />
                </svg>
            </button>
            <button class="hover:text-primary duration-100 p-2 -mx-2 hidden md:block" @click="navigateTo('/')">
                <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                    <path
                        d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                    />
                </svg>
            </button>
            <button class="hover:text-primary duration-100 p-2 -mx-2 hidden md:block" @click="navigateTo('/')">
                <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                    <path
                        d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                    />
                </svg>
            </button>
        </div>

        <!-- lower -->
        <div class="border-t border-gray-300 py-4 md:py-8">
            <div class="container flex items-center gap-4">
                <NuxtLink active-class="text-primary" to="/" class="shrink-0">
                    <img src="~/assets/images/logo.svg" alt="" class="w-14 md:w-16" />
                </NuxtLink>

                <div class="flex flex-wrap gap-x-0.5 pointer-events-none select-none grow">
                    <img src="~/assets/images/partner/abarth.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/alfa-romeo.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/citroen.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/ds-automobiles.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/fiat.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/fiat-professional.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/hyundai.webp" alt="" class="w-5 md:w-7" />
                    <div class="w-full xl:hidden"></div>
                    <img src="~/assets/images/partner/jeep.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/kia.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/lancia.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/opel.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/nissan.webp" alt="" class="w-5 md:w-7" />
                    <img src="~/assets/images/partner/peugeot.webp" alt="" class="w-5 md:w-7" />
                </div>

                <nav class="ml-auto shrink-0 flex gap-6 justify-end">
                    <ul class="items-center gap-6 uppercase font-medium hidden lg:flex">
                        <li class="hidden xl:block">
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/">Home</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/firmenwagen-finder"
                                >Firmenwagen-Finder</NuxtLink
                            >
                        </li>
                        <li>
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/abrufschein"
                                >Abrufschein</NuxtLink
                            >
                        </li>
                        <li>
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/top-deals"
                                >Top Deals</NuxtLink
                            >
                        </li>

                        <li>
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/ueber-uns">Über uns</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink active-class="text-primary" class="hover:underline" to="/haendler">Händler</NuxtLink>
                        </li>
                    </ul>
                    <button
                        aria-label="Navigation öffnen"
                        class="p-2 -mr-2 text-primary hover:text-cyan-950 duration-100"
                        @click="$emit('showNav', true)"
                    >
                        <svg class="size-6 shrink-0 fill-current" viewBox="0 -960 960 960" aria-hidden="true">
                            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </header>

    <Modal v-model="modal">
        <aside class="bg-white fixed -top-4 -bottom-4 right-0 w-72 shadow-xl shadow-black/30 py-8 z-50">
            <button
                aria-label="Menü schließen"
                class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-8 right-4 flex text-gray-500 duration-100"
                @click="$emit('showNav', false)"
            >
                <Icon name="close" class="m-auto" :size="22"></Icon>
            </button>

            <ul class="uppercase font-medium">
                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/"
                        @click="$emit('showNav', false)"
                        >Home</NuxtLink
                    >
                </li>

                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/firmenwagen-finder"
                        @click="$emit('showNav', false)"
                        >Firmenwagen-Finder</NuxtLink
                    >
                </li>
                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/abrufschein"
                        @click="$emit('showNav', false)"
                        >Abrufschein</NuxtLink
                    >
                </li>
                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/top-deals"
                        @click="$emit('showNav', false)"
                        >Top Deals</NuxtLink
                    >
                </li>

                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/ueber-uns"
                        @click="$emit('showNav', false)"
                        >Über uns</NuxtLink
                    >
                </li>

                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/haendler"
                        @click="$emit('showNav', false)"
                        >Händler</NuxtLink
                    >
                </li>
                <li>
                    <NuxtLink
                        active-class="text-primary"
                        class="px-6 py-2.5 block w-fit hover:underline"
                        to="/mitglied-werden"
                        @click="$emit('showNav', false)"
                        >Mitglied werden</NuxtLink
                    >
                </li>

                <li class="border-t border-gray-300 my-2 mx-6"></li>

                <li class="">
                    <button
                        class="flex gap-0.5 py-2.5 items-center px-6 uppercase w-fit"
                        @click="$emit('showNav', false), $emit('showLogin', true)"
                    >
                        <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                        <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                        <img src="https://placehold.co/50" class="rounded-full w-4 select-none" />
                        <span class="ml-2 font-medium hover:underline">Login für Mitglieder</span>
                    </button>
                </li>

                <li class="flex justify-center gap-4 mt-12">
                    <button
                        class="hover:text-primary duration-100 p-2 -mx-2"
                        @click="$emit('showNav', false), navigateTo('/')"
                    >
                        <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                            <path
                                d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                            />
                        </svg>
                    </button>
                    <button
                        class="hover:text-primary duration-100 p-2 -mx-2"
                        @click="$emit('showNav', false), navigateTo('/')"
                    >
                        <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                            <path
                                d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                            />
                        </svg>
                    </button>
                    <button
                        class="hover:text-primary duration-100 p-2 -mx-2"
                        @click="$emit('showNav', false), navigateTo('/')"
                    >
                        <svg aria-hidden="true" class="w-[18px] fill-current" viewBox="0 0 24 24">
                            <path
                                d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                            />
                        </svg>
                    </button>
                </li>
            </ul>
        </aside>
    </Modal>
</template>

<script setup>
const props = defineProps({
    account: {
        type: Object,
        default: null,
    },

    data: {
        type: Object,
        default: () => {},
    },
});

const emit = defineEmits(['showNav', 'showLogin']);

const modal = computed({
    get() {
        return props.data.open;
    },
    set(value) {
        emit('showNav', value);
    },
});

const menuListener = ref(false);
const menuElement = useTemplateRef('menu-button');
</script>
